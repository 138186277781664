import { satisfies } from "compare-versions";
import { find, memoize } from "lodash";

// To future traveler who want to add or modify the framework version:
// If the change you are making involves breaking changes to the editor, and you think the change will affect other framework versions,
// go to `EditorVersion.ts` to bump the editor version. It will notify all active editors to reload the page.

export const FRAMEWORK_VERSION_CONSTRAINTS = ["^0.1.0", "^0.2.0", "^0.3.0", "^0.3.1", "^1.0.0", "^1.1.0", "^1.2.0", "^1.3.0"] as const;
export type FrameworkVersionConstraint = (typeof FRAMEWORK_VERSION_CONSTRAINTS)[number];

export interface FrameworkVersion {
  constraint: FrameworkVersionConstraint;
  name: string;
  released: boolean;
  beta?: boolean;
  description: string;
  nodeVersion: "16" | "18" | "20";
  fastifyVersion: "3" | "4";
}

export const AvailableVersions: FrameworkVersion[] = [
  {
    constraint: "^0.1.0",
    name: "v0.1",
    released: true,
    description: "Node 16.20, Fastify 3",
    nodeVersion: "16",
    fastifyVersion: "3",
  },
  {
    constraint: "^0.2.0",
    name: "v0.2",
    released: true,
    description: "Node 18.15, Fastify 3",
    nodeVersion: "18",
    fastifyVersion: "3",
  },
  {
    constraint: "^0.3.0",
    name: "v0.3",
    released: true,
    description: "Node 20.9, Fastify 4",
    nodeVersion: "20",
    fastifyVersion: "4",
  },
  {
    constraint: "^0.3.1",
    name: "v0.3.1",
    released: true,
    description: "Node 20.9, Fastify 4, support for Shopify webhook disabling",
    nodeVersion: "20",
    fastifyVersion: "4",
  },
  {
    constraint: "^1.0.0",
    name: "v1.0.0",
    released: true,
    description: "Node 20.9, Fastify 4, support for source control and multi-environment",
    nodeVersion: "20",
    fastifyVersion: "4",
  },
  {
    constraint: "^1.1.0",
    name: "v1.1.0",
    released: true,
    beta: false,
    description: "Node 20.12, Fastify 4",
    nodeVersion: "20",
    fastifyVersion: "4",
  },
  {
    constraint: "^1.2.0",
    name: "v1.2.0",
    released: true,
    beta: false,
    description: "Node 20.12, Fastify 4",
    nodeVersion: "20",
    fastifyVersion: "4",
  },
  {
    constraint: "^1.3.0",
    name: "v1.3.0",
    released: false,
    description: "Node 20.12, Fastify 4",
    nodeVersion: "20",
    fastifyVersion: "4",
  },
];

export const latestVersion = memoize(() => AvailableVersions.findLast((version) => version.name != "edge" && version.released)!);

export const secondLatestReleasedVersion = memoize(() => {
  const releasedVersions = AvailableVersions.filter((version) => version.name != "edge" && version.released);

  if (releasedVersions.length < 2) {
    throw new Error("Not enough released versions to determine second latest");
  }
  return releasedVersions[releasedVersions.length - 2];
});

export const edgeVersion = memoize(() => AvailableVersions.at(-1)!);

export const AvailableVersionNames = AvailableVersions.map((version) => version.name);

export const latestConstraint = memoize(() => latestVersion().constraint);
export const edgeConstraint = memoize(() => edgeVersion().constraint);

export const labelForConstraint = (constraint: string) =>
  find(AvailableVersions, { constraint: constraint as FrameworkVersionConstraint })?.name ?? constraint;

export const doesVersionSupportSourceControl = (constraint: string) => {
  return satisfies(constraint, ">=1.0.0");
};
export const doesVersionSupportNamespace = (constraint: string) => {
  return satisfies(constraint, ">=1.1.0");
};
export const doesVersionSupportGadgetVitePlugin = (constraint: string) => {
  return satisfies(constraint, ">=1.2.0");
};

export const latestVersionSatisfyingConstraint = (constraint: string) => {
  return AvailableVersions.findLast((version) => satisfies(version.constraint, constraint));
};

export const constraintForLabel = (label: string) => find(AvailableVersions, (item) => item.name === label)?.constraint;

export const AvailableV1Versions: FrameworkVersion[] = AvailableVersions.filter((version) =>
  doesVersionSupportSourceControl(version.constraint)
);
